import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss']
})
export class ImageInputComponent implements OnInit {

  @ViewChild('fileInput' , { static: false }) fileInput: ElementRef<any>;

  @Output() change = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  addFile() {
    this.fileInput.nativeElement.click();
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  selected(files: FileList, event: any): void {
    if (files.length > 0) {
      const onChange = this.change;
      const fileInput = this.fileInput;

      const filesList = [];
      let counter = 0;

      for (let index = 0; index < files.length; index++) {
        this.getBase64(files[index]).then(base64 => {
          const image = new Image();

          image.onload = function() {
            filesList.push({
              image: base64,
              author: '',
              place: '',
              date: '',
              notes: ''
            });
            counter++;

            if (counter === files.length) {
              onChange.emit(filesList);
              fileInput.nativeElement.value = '';
            }
          };
          image.src = URL.createObjectURL(files[index]);
        });
      }
    }

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
