import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dropdown-languages',
  templateUrl: './dropdown-languages.component.html',
  styleUrls: ['./dropdown-languages.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0 }),
          animate('250ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('250ms', style({ opacity: 0 }))
        ])
      ]
    )
  ],
})
export class DropdownLanguagesComponent implements OnInit {

  @Input() showDropdown: boolean = false;

  languageOptions: any[] = [
    {
      id: 'pt_PT',
      label: 'Português',
      code: "PT"
    },
    {
      id: 'en_GB',
      label: 'English',
      code: "EN"
    }
  ]

  selectedLanguage: any = this.languageOptions[0];

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
    if (localStorage.getItem('usedLanguage')) {
      this.selectedLanguage = this.languageOptions.find(lan => lan.id === localStorage.getItem('usedLanguage'));
    } else {
      this.selectedLanguage = this.languageOptions[0];
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  closeDropdown() {
    if (this.showDropdown) {
      this.toggleDropdown();
    }
  }

  switchLanguage(option: any) {
    this.selectedLanguage = option;
    this.translate.use(option.id);


    localStorage.setItem('usedLanguage', option.id);

    const destinationRoute = this.router.url;

    // HACK to refresh page after a new language defined
    this.router.navigate(['/refresh']);
    setTimeout(() => {
      this.router.navigate([destinationRoute]);
    });
  }
}
