import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from '@app/services/user.service';
import { trigger, transition, style, animate, query, stagger, state, group } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Biodiversidade Angola';

  constructor(
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private doc: any
  ) {
    // Listening route events and send to Google Analytics
    if (environment.analytics) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.analytics, { 'page_path': event.urlAfterRedirects });
        }
      });
    }
  }

  ngOnInit() {
    this.userService.verifyLoggedUser();
    this.initTranslationService();
  }

  initTranslationService() {
    this.translate.addLangs(['pt_PT', 'en_GB']);
    this.translate.setDefaultLang('pt_PT'); // used as a fallback when a translation isn't found in the current lang;

    const sessionLanguage = localStorage.getItem('usedLanguage');
    if (sessionLanguage) {
      this.translate.use(sessionLanguage);
    } else {
      this.translate.use('pt_PT');
      localStorage.setItem('usedLanguage', 'pt_PT');
    }
  }
}

// #####################
// ANIMATIONS
// #####################

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true}
    )
  ])
]);

export const collapseAnimation = trigger('collapseAnimation', [
  state('in', style({height: '*', opacity: 0})),
  transition(':leave', [
      style({height: '*', opacity: 1}),

      group([
          animate(300, style({height: 0})),
          animate('200ms ease-in-out', style({'opacity': '0'}))
      ])

  ]),
  transition(':enter', [
      style({height: '0', opacity: 0}),

      group([
          animate(300, style({height: '*'})),
          animate('400ms ease-in-out', style({'opacity': '1'}))
      ])

  ])
])

export const slideshowFade = [
  trigger('slideshowFade', [
    state('in', style({ 'opacity': '1' })),
    state('out', style({ 'opacity': '0' })),
    transition('* <=> *', [
      animate(500)
    ])
  ])
];