import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loggedUser = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  login(username: string, password: string) {
    const subj = new Subject();
    const url = environment.apiBaseUrl + `/user/signin`;

    const body = new HttpParams()
      .set('username', username)
      .set('password', password);

    this.http.post(url, body).subscribe(
      resp => {
        this.loggedUser.next(resp);
        sessionStorage.setItem('user', JSON.stringify(resp));
        subj.next(resp);
      },
      error => {
        subj.next(error);
      }
    );

    return subj;
  }

  logout() {
    this.loggedUser.next({});
    sessionStorage.removeItem('user');
    this.router.navigate(['/']);
  }

  // HANDLERS
  // --------

  isLoggedIn() {
    return this.loggedUser.getValue().hasOwnProperty('name');
  }

  verifyLoggedUser() {
    if (sessionStorage.getItem('user')) {
      const user = JSON.parse(sessionStorage.getItem('user'));
      this.loggedUser.next(user);
    }
  }
}
