import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {

  @Input() value = '';
  @Input() placeholder = '';
  @Input() type = '';

  @Output() onChange = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() inputKeyUpEnter = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  changed(event: any) {
    event.preventDefault();
    this.onChange.emit(this.value);
  }

  onBlur(e) {
    e.preventDefault();
    this.blur.emit(this.value);
  }

  onEnter(event: any) {
    event.preventDefault();
    this.inputKeyUpEnter.emit(this.value);
  }

  deleteText() {
    this.value = '';
    this.onChange.emit(this.value);
  }
}
