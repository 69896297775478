import { Component, OnInit, Input } from '@angular/core';
import { ImageViewService, ImageViewSettings } from './image-view.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.scss'],
  animations: [
    trigger('modal', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(200)
      ]),
      transition('* => void', [
        animate(200, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class ImageViewComponent implements OnInit {
  @Input() viewId: string;

  visible: boolean;
  settings: ImageViewSettings;

  constructor(
    private ImageViewService: ImageViewService) {}

  ngOnInit() {
    this.ImageViewService.state.subscribe(s => {
      if (s) {
        this.visible = true;
        this.settings = s;
      } else {
        this.visible = false;
      }
    });
  }

  closeModal(): void {
    this.ImageViewService.close();
  }

  optionSelected() {
    return this.ImageViewService.getOptionSelected();
  }

}
