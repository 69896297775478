import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ImageViewService } from '../image-view/image-view.service';
import { trigger, style, transition, animate, state } from '@angular/animations';

@Component({
  selector: 'app-georeference-photo-view',
  templateUrl: './georeference-photo-view.component.html',
  styleUrls: ['./georeference-photo-view.component.scss'],
  animations: [
    trigger('fade', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('300ms')),
    ])
  ]
})
export class GeoreferencePhotoViewComponent implements OnInit {

  @Input() selectedPhotoId: number = 0;
  @Input() photos: any[] = [];

  loadingImage: boolean = true;

  constructor(
    public imageViewService: ImageViewService
  ) { }

  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.next();
    } else if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.previous();
    } else if (event.keyCode === KEY_CODE.ESC) {
      this.imageViewService.close();
    }
  }

  onImageLoad(evt) {
    if (evt && evt.target) {
      this.loadingImage = false;
    }
  }

  ngOnInit() {}

  hasNextPhoto() {
    return this.selectedPhotoId !== this.photos.length - 1;
  }

  hasPreviousPhoto() {
    return this.selectedPhotoId !== 0;
  }

  next() {
    if (this.hasNextPhoto()) {
      this.loadingImage = true;
      this.selectedPhotoId++;
    }
  }

  previous() {
    if (this.hasPreviousPhoto()) {
      this.loadingImage = true;
      this.selectedPhotoId--;
    }
  }

  closePhotoView(event: any) {
    if (event && event.target && event.target.id !== 'slide-arrow-left' && event.target.id !== 'slide-arrow-right') {
      this.imageViewService.close();
    }
  }
}

export enum KEY_CODE {
  ESC = 27,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}
