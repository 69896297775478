import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: 'app/modules/landing/landing.module#LandingModule'
  },
  {
    path: 'credits',
    loadChildren: 'app/modules/credits/credits.module#CreditsModule'
  },
  {
    path: 'amphibians',
    loadChildren: 'app/modules/amphibians/amphibians.module#AmphibiansModule'
  },
  {
    path: 'birds',
    loadChildren: 'app/modules/birds/birds.module#BirdsModule'
  },
  {
    path: 'lepidopteras',
    loadChildren: 'app/modules/lepidopteras/lepidopteras.module#LepidopterasModule'
  },
  {
    path: 'mammals',
    loadChildren: 'app/modules/mammals/mammals.module#MammalsModule'
  },
  {
    path: 'odonatas',
    loadChildren: 'app/modules/odonatas/odonatas.module#OdonatasModule'
  },
  {
    path: 'plants',
    loadChildren: 'app/modules/plants/plants.module#PlantsModule'
  },
  {
    path: 'reptiles',
    loadChildren: 'app/modules/reptiles/reptiles.module#ReptilesModule'
  },
  {
    path: 'ecosystems',
    loadChildren: 'app/modules/ecosystems/ecosystems.module#EcosystemsModule'
  },
  {
    path: 'cpanel',
    loadChildren: 'app/modules/login/login.module#LoginModule'
  },
  {
    path: 'refresh',
    loadChildren: 'app/modules/refresh-page/refresh-page.module#RefreshPageModule'
  },
  {
      path: '**',
      redirectTo: '',
      pathMatch: 'full'
  }
];

export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes,
  { enableTracing: false, preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled'  } // <-- debugging purposes only
);
