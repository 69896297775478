import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dropdown-categories',
  templateUrl: './dropdown-categories.component.html',
  styleUrls: ['./dropdown-categories.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0 }),
          animate('250ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('250ms', style({ opacity: 0 }))
        ])
      ]
    )
  ],
})
export class DropdownCategoriesComponent implements OnInit {

  @Input() showDropdown: boolean = false;

  categoriesOptions: any[] = [
    { id: 'birds', label: 'landing.categories.birds', route: '/birds' },
    { id: 'plants', label: 'landing.categories.plants', route: '/plants' },
    { id: 'odonatas', label: 'landing.categories.odonatas', route: '/odonatas' },
    { id: 'mammals', label: 'landing.categories.mammals', route: '/mammals' },
    { id: 'reptiles', label: 'landing.categories.reptiles', route: '/reptiles' },
    { id: 'lepidopteras', label: 'landing.categories.lepidopteras', route: '/lepidopteras' },
    { id: 'amphibians', label: 'landing.categories.amphibians', route: '/amphibians' },
    { id: 'ecosystems', label: 'landing.categories.ecosystems', route: '/ecosystems/info/9791' }
  ];

  constructor() { }

  ngOnInit() { }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  closeDropdown() {
    if (this.showDropdown) {
      this.toggleDropdown();
    }
  }
}
