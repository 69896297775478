import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';

import { HeaderComponent } from './header/header.component';
import { ImageComponent } from './image/image.component';
import { MapComponent } from './map/map.component';
import { TextInputComponent } from '@app/shared/text-input/text-input.component';
import { TextAreaComponent } from '@app/shared/text-area/text-area.component';
import { ImageInputComponent } from './image-input/image-input.component';
import { ModalComponent } from './modal/modal.component';
import { ButtonComponent } from './button/button.component';
import { LoadingSpinnerComponent } from '@app/shared/loading-spinner/loading-spinner.component';
import { ImageViewComponent } from './image-view/image-view.component';
import { DropdownLanguagesComponent } from './dropdown-languages/dropdown-languages.component';
import { GeoreferencePhotoViewComponent } from './georeference-photo-view/georeference-photo-view.component';
import { GeoreferencePhotoEditComponent } from './georeference-photo-edit/georeference-photo-edit.component';
import { DropdownCategoriesComponent } from './dropdown-categories/dropdown-categories.component';
import { SearchInputComponent } from './search-input/search-input.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LeafletModule,
    FormsModule,
    TranslateModule,
    ClickOutsideModule
  ],
  declarations: [
    HeaderComponent,
    ImageComponent,
    MapComponent,
    TextInputComponent,
    TextAreaComponent,
    ImageInputComponent,
    ModalComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    ImageViewComponent,
    DropdownLanguagesComponent,
    GeoreferencePhotoViewComponent,
    GeoreferencePhotoEditComponent,
    DropdownCategoriesComponent,
    SearchInputComponent
  ],
  exports: [
    CommonModule,
    TranslateModule,
    HeaderComponent,
    ImageComponent,
    MapComponent,
    TextInputComponent,
    TextAreaComponent,
    ImageInputComponent,
    ModalComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    ImageViewComponent,
    DropdownLanguagesComponent,
    GeoreferencePhotoViewComponent,
    GeoreferencePhotoEditComponent,
    DropdownCategoriesComponent,
    SearchInputComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}

