import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '@app/shared/modal/modal.service';

@Component({
  selector: 'app-georeference-photo-edit',
  templateUrl: './georeference-photo-edit.component.html',
  styleUrls: ['./georeference-photo-edit.component.scss']
})
export class GeoreferencePhotoEditComponent implements OnInit {

  @Input() photoDetails: any = null;

  @Output() onChange = new EventEmitter();

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit() {
  }

  savePhotoInfo() {
    event.preventDefault();
    this.onChange.emit(this.photoDetails);
    this.modalService.close();
  }

}
