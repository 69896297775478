import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  ngUnsubscribe = new Subject<boolean>();
  isLanding: boolean = false;

  constructor(
    private router: Router,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: any) => {
      if (event.url) {
      this.isLanding = event.url === '/';
      }
    });
  }

  logout() {
    this.userService.logout();
  }

}
