import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {

  @Input() value: string = '';
  @Input() placeholder: string = '';
  @Input() rows: number;
  @Input() cols: number;

  @Output() onChange = new EventEmitter();
  @Output() blur = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  changed(event: any) {
    event.preventDefault();
    this.onChange.emit(this.value);
  }

  onBlur(e) {
    e.preventDefault();
    this.blur.emit(this.value);
  }
}
