import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { tileLayer, latLng, circle, Map, Layer } from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnChanges {

  @Input() coordinates: any[] = [];
  @Input() canEdit: boolean = false;

  @Output() onChange = new EventEmitter();

  newLayer: Layer = null;
  canAddNewLayer: boolean;
  newLayerAdded: boolean;

  _map: Map = null;

  options = {
    layers: [
      tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      {
        maxZoom: 20,
        minZoom: 5.9,
        attribution: 'Angola',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      })
    ],
    zoom: 5.9,
    zoomSnap: 0.1,
    center: latLng(-11.3500, 17.5739) // Angola Latitude and Longitude
  };

  layers: Layer[] = [];

  constructor() { }

  ngOnInit() {}

  ngOnChanges() {
    this.coordinates.map(coordinate => {
      this.layers.push(
        circle([coordinate.latitude, coordinate.longitude], { radius: 2500, color: '#99ff02' })
      );
    });
  }

  onMapReady(map: Map) {
    this._map = map;
    map.on('click', (e: any) => {
      const _coordinate = e.latlng;
      const layer = circle([_coordinate.lat, _coordinate.lng], { radius: 2500, color: '#99ff02' });

      if (this.canAddNewLayer && !this.newLayerAdded) {
        this.newLayerAdded = true;
        this.layers.push(layer);
        map.addLayer(layer);

        this.coordinates.push(
          {
            coordinateId: -1,
            latitude: _coordinate.lat,
            longitude: _coordinate.lng
          }
        );
        this.onChange.emit(this.coordinates);
      } else if (this.canAddNewLayer && this.newLayerAdded) {
        this.coordinates.pop();
        map.removeLayer(this.layers.pop());
        this.layers.push(layer);
        map.addLayer(layer);

        this.coordinates.push(
          {
            coordinateId: -1,
            latitude: _coordinate.lat,
            longitude: _coordinate.lng
          }
        );
        this.onChange.emit(this.coordinates);
      }
    }).on('dbclick', (e: any) => {
      // do something...
    }).on('contextmenu', (e: any) => {
      // do something when rightclick
    });
  }

  addCoordinate() {
    this.canAddNewLayer = true;
    this.newLayerAdded = false;
  }

  saveCoordinate() {
    this.canAddNewLayer = false;
  }

  removeCoordinate(id: number) {
    this._map.removeLayer(this.layers[id]);
    this.layers.splice(id, 1);
    this.coordinates.splice(id, 1);
    this.onChange.emit(this.coordinates);
  }

  viewCoordinate(id: number) {
    this._map.flyTo([this.coordinates[id].latitude, this.coordinates[id].longitude], 10);
  }
}
