import { Injectable, Input} from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

export class ModalSettings {
  option: string;
  closeButton: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  state = new BehaviorSubject<ModalSettings>(null);
  selectedOption$: Subject<string>;
  optionSelected: string;

  constructor() { }

  open(settings: ModalSettings): Subject<string> {
    this.state.next(settings);
    this.selectedOption$ = new Subject();
    this.optionSelected = settings.option;
    return this.selectedOption$;
  }

  close(): void {
    this.state.next(null);
  }

  getOptionSelected() {
    return this.optionSelected;
  }
}
